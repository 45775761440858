import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "../Util/Select";
import { faAdd, faDownload, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useRef, useState } from "react";
import Loading from "../Util/Loading";
import Input from "../Util/Input";
export default function QueryBuilder(props) {
  const [loading, setLoading] = useState(false);

  const [reporting, setReporting] = useState(false);
  const [columns, setColumns] = useState(null);
  const [data, setData] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [tables, setTables] = useState([]);
  const [cols, setCols] = useState([]);
  const [selected, setSelected] = useState(null);
  const [filters, setFilters] = useState([]);
  const col = useRef();
  const op = useRef();
  const vl = useRef();

  function addFilter() {
    const cl = col.current.value;
    const o = op.current.value;
    const v = vl.current.value;
    if (cl != "" && o != "" && v != "") {
      let d = {
        column: cl,
        operator: o,
        value: v,
      };
      setFilters([...filters, d]);
    }
  }

  useEffect(() => {
    setLoading(true);
    fetch(`/api/myreports/alltables`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        setLoading(false);
        setTableData(data);
        const tts = data.map((obj) => obj.table_name);
        setTables(tts);
        setSelected(tts[0]);
      })
      .catch((e) => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (selected != null) {
      setLoading(true);
      const i = tableData
        .map((obj) => {
          return obj.table_name;
        })
        .indexOf(selected);

      fetch(
        `/api/myreports/tablecolumns/${selected}/${tableData[i].table_schema}`
      )
        .then((res) => {
          if (res.ok) return res.json();
          else throw Error("");
        })
        .then((data) => {
          setLoading(false);
          const tts = data.map((obj) => obj.column_name);
          setCols(tts);
        })
        .catch((e) => {
          setLoading(false);
        });
    }
  }, [selected]);

  function getData() {
    setData(null);
    if (selected != null) {
      const i = tableData
        .map((obj) => {
          return obj.table_name;
        })
        .indexOf(selected);

      setLoading(true);
      fetch(`/api/myreports/getdata/${selected}/${tableData[i].table_schema}`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({ filters: filters }),
      })
        .then((res) => {
          if (res.ok) return res.json();
          else throw Error("");
        })
        .then((data) => {
          setLoading(false);
          if (data.length > 0) {
            let dt = data[0];
            delete dt.geom;
            delete dt.ID;
            delete dt.id;
            const c = Object.keys(dt).filter((key) => !key.includes("ID"));
            setColumns(c.slice(0, 6));
            setData(data);
          }
        })
        .catch((e) => {
          setLoading(false);
        });
    }
  }

  const saveData = (data) => {
    if (data.length > 0) {
      let rows = [];
      rows.push(Object.keys(data[0]));
      data.map((item) => {
        rows.push(Object.values(item));
      });
      let csvContent =
        "data:text/csv;charset=utf-8," +
        rows.map((e) => e.join(",")).join("\n");

      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "data.csv");
      document.body.appendChild(link);
      link.click();
    }
  };

  return (
    <div>
      <div className="top">
        <h3>Query Builder</h3>
        <hr />

        <div className="div1auto">
          <Select
            setChanged={(v) => {
              setSelected(v);
            }}
            label="Select Data Table"
            data={tables}
          />
          <button
            onClick={() => {
              setReporting(true);
            }}
          >
            Add Filter
          </button>
        </div>
        {reporting && (
          <div className="add">
            <Select ref={col} label="Column" data={cols} />
            <Select
              ref={op}
              label="Operator"
              data={["=", ">", ">=", "<", "<=", "ILIKE"]}
            />
            <Input ref={vl} label="Value" />
            <FontAwesomeIcon
              onClick={() => {
                addFilter();
                setReporting(false);
              }}
              className="btn"
              icon={faAdd}
            />
          </div>
        )}

        <div className="div3equal">
          {filters.map((item, i) => {
            return (
              <TItem
                key={i}
                index={i}
                item={item}
                setFilters={setFilters}
                filters={filters}
              />
            );
          })}
        </div>

        <button
          onClick={() => {
            getData();
          }}
        >
          Retrieve Data
        </button>
      </div>{" "}
      <div className="list">
        <div className="div1auto">
          <h4>Data</h4>
          <FontAwesomeIcon
            onClick={() => {
              if (data) {
                saveData(data);
              }
            }}
            className="download"
            icon={faDownload}
          />
        </div>
        <div className="data">
          <div className="head">
            <h4>No.</h4>
            {columns &&
              columns.map((e, i) => {
                return <h4 key={i}>{e}</h4>;
              })}
          </div>
          {data &&
            data.length > 0 &&
            data.map((item, i) => {
              return <Item key={i} item={item} index={i} />;
            })}
        </div>
      </div>
      {loading && <Loading />}
    </div>
  );
}

const TItem = (props) => {
  function removeItem() {
    const newArray = [...props.filters];
    newArray.splice(props.index, 1);
    props.setFilters(newArray);
  }
  return (
    <div className="item">
      <p>{props.item.column}</p>
      <p>{props.item.operator}</p>
      <p>{props.item.value}</p>
      <FontAwesomeIcon
        onClick={() => {
          removeItem();
        }}
        icon={faTimes}
        className="fas"
      />
    </div>
  );
};

const Item = (props) => {
  const [values, setValues] = useState(null);
  const [clicked, setClicked] = useState(false);
  useEffect(() => {
    if (props.item) {
      let dt = props.item;
      delete dt.geom;
      delete dt.ID;
      delete dt.id;
      const v = Object.values(
        Object.fromEntries(
          Object.entries(dt).filter(([key]) => !key.includes("ID"))
        )
      );
      // Object.values(dt);
      setValues(v.slice(0, 6));
    }
  }, [props.item]);

  return (
    <>
      <div
        onClick={() => {
          setClicked(true);
        }}
        className="item"
      >
        <p>{props.index + 1}</p>
        {values &&
          values.map((e, i) => {
            return <p key={i}>{e}</p>;
          })}
      </div>
      {clicked && (
        <Popup item={props.item} setClicked={setClicked} index={props.index} />
      )}
    </>
  );
};

const Popup = (props) => {
  const [values, setValues] = useState(null);
  const [cols, setCols] = useState(null);
  const [data, setData] = useState(null);
  useEffect(() => {
    if (props.item) {
      let ds = props.item;
      delete ds.geom;
      setData([ds]);
      let dt = props.item;
      delete dt.geom;
      delete dt.ID;
      delete dt.id;

      const v = Object.values(dt);
      const c = Object.keys(dt);
      setValues(v);
      setCols(c);
    }
  }, [props.item]);

  const DItem = (params) => {
    return (
      <div className="ditem">
        <h4>{params.column}</h4>
        <p>{params.value}</p>
      </div>
    );
  };

  const saveData = (data) => {
    if (data.length > 0) {
      let rows = [];
      rows.push(Object.keys(data[0]));
      data.map((item) => {
        rows.push(Object.values(item));
      });
      let csvContent =
        "data:text/csv;charset=utf-8," +
        rows.map((e) => e.join(",")).join("\n");

      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "data.csv");
      document.body.appendChild(link);
      link.click();
    }
  };

  return (
    <div className="popup">
      <div className="container">
        <div className="div1auto">
          <h4>Data Item {props.index}</h4>
          <FontAwesomeIcon
            onClick={() => {
              props.setClicked(false);
            }}
            color="white"
            icon={faTimes}
          />
        </div>
        <div className="data">
          <div className="div2equal">
            {cols &&
              cols.map((c, i) => {
                return <DItem key={i} column={c} value={values[i]} />;
              })}
          </div>
        </div>
        <button
          onClick={() => {
            if (data) {
              saveData(data);
            }
          }}
        >
          Download Record
        </button>
      </div>
    </div>
  );
};
