import "../../Styles/requests.scss";
import Input from "../Util/Input";
import { useRef, useState } from "react";
import { useEffect } from "react";
import WaveLoading from "../Util/WaveLoading";
import Select from "../Util/Select";
import Button from "../Util/Button";
import { refresh } from "aos";

export default function NewDocument(props) {
  const [refresh, setRefresh] = useState(false);
  const [active, setActive] = useState("Basic Details");
  const [showing, setShowing] = useState(true);

  const handleResize = () => {
    if (window.innerWidth < 768) {
      setShowing(false);
    } else {
      setShowing(true);
    }
  };

  useEffect(() => {
    handleResize();
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("project");
    const step2 = localStorage.getItem("step2");
    if (token) {
      if (!step2) {
        setActive("Milestones");
      } else {
        setActive("Classification");
      }
    } else {
      setActive("Basic Details");
    }
  }, [refresh]);

  const BarItem = (params) => {
    return (
      <p className={params.txt === params.active ? "active" : ""}>
        {params.txt}
      </p>
    );
  };

  return (
    <div className="requests">
      <div className="list">
        <h3>New Project</h3>
        {/* <hr /> */}
        <div className="bar">
          <BarItem txt="Basic Details" active={active} />
          <BarItem txt="Milestones" active={active} />
          <BarItem txt="Classification" active={active} />
        </div>
        <div className="new">
          {active === "Basic Details" && (
            <Step1 refresh={refresh} setRefresh={setRefresh} />
          )}
          {active === "Milestones" && (
            <Step2 refresh={refresh} setRefresh={setRefresh} />
          )}
          {active === "Classification" && (
            <Step3 refresh={refresh} setRefresh={setRefresh} />
          )}
        </div>
      </div>
    </div>
  );
}

const Step1 = (props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const pn = useRef();
  const ca = useRef();
  const yr = useRef();
  const tno = useRef();
  const sdt = useRef();
  const edt = useRef();
  const cn = useRef();
  const desc = useRef();
  const geom = useRef();
  const lat = useRef();
  const lon = useRef();

  function submitData() {
    setError("");
    let yrr;
    try {
      const dt = new Date(sdt.current.value);
      yrr = dt.getFullYear();
    } catch (error) {}
    const body = {
      ProjectName: pn.current.value,
      ContractAmount: ca.current.value,
      Year: yrr,
      TenderNo: tno.current.value,
      StartDate: sdt.current.value,
      EndDate: edt.current.value,
      Consultant: cn.current.value,
      ProjectDescription: desc.current.value,
      Geometry: geom.current.value,
      Latitude: lat.current.value,
      Longitude: lon.current.value,
    };

    const chck = Object.values(body);
    let valid = true;
    chck.map((item) => {
      if (item === "" || item == null) {
        valid = false;
      }
    });
    if (!valid) return setError("All fields are required!");
    setLoading(true);
    fetch(`/api/projects/create`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else throw Error("");
      })
      .then((data) => {
        setLoading(false);
        if (data.success) {
          setError(data.success);

          localStorage.setItem("project", data.id);
          setTimeout(() => {
            props.setRefresh(!props.refresh);
          }, 1000);
        } else {
          setError(data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  return (
    <div className="list">
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
        action=""
      >
        <h5>Basic Details</h5>
        <hr />
        <Input ref={pn} label="Project Name" type="text" />
        <Input ref={desc} label="Project Description" type="textarea" />
        <Input ref={cn} label="Consultant" type="text" />
        <div className="div2equal">
          <Input ref={tno} label="Tender/Quotation No" type="text" />
          <Input ref={ca} label="Project Budget" type="number" />
        </div>

        <div className="div2equal">
          <Input ref={sdt} label="Start Date" type="date" />
          <Input ref={edt} label="End Date" type="date" />
        </div>

        <h5>Other Details</h5>
        <hr />

        <div className="div2equal">
          <Input ref={lat} label="Latitude" type="number" />
          <Input ref={lon} label="Longitude" type="number" />
        </div>

        <Select
          ref={geom}
          label="GIS Geometry Type"
          data={["Point", "Polygon", "Linear"]}
        />

        <br />
        <h6>{error}</h6>
        <br />
        <Button value="Submit" handleClick={submitData} />
      </form>
      {loading && <WaveLoading />}
    </div>
  );
  function isNumeric(str) {
    if (typeof str != "string") return false;
    return !isNaN(str) && !isNaN(parseFloat(str));
  }
};

const Step2 = (props) => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(null);
  const [data, setData] = useState(null);
  const [adding, setAdding] = useState(false);
  const obj = useRef();
  const dt = useRef();
  const bgt = useRef();

  const [body, setBody] = useState({
    ProjectID: null,
    Budget: null,
    Objective: null,
    Date: null,
  });

  useEffect(() => {
    const token = localStorage.getItem("project");
    if (token != undefined) {
      let d = body;
      d.ProjectID = token;
      setBody(d);
      fetch(`/api/objectives/byprojectid/${token}`)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else throw Error("");
        })
        .then((data) => {
          setData(data);
        })
        .catch((e) => {});
    }
  }, [props.refresh]);

  const createDocument = (e) => {
    setError("");
    let d = body;
    d.Objective = obj.current.value;
    d.Budget = bgt.current.value;
    d.Date = dt.current.value;
    setBody(d);
    const chck = Object.values(d);
    let valid = true;
    chck.map((item) => {
      if (item === "" || item == null) {
        valid = false;
      }
    });
    if (!valid) return setError("All fields are required!");
    setLoading(true);
    fetch("/api/objectives/create", {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(d),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else throw Error("");
      })
      .then((data) => {
        setLoading(false);
        if (data.success) {
          setError(data.success);

          setTimeout(() => {
            props.setRefresh(!props.refresh);
          }, 1000);
        } else {
          setError(data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        setError("Oops! Something went wrong!");
      });
  };

  const UserList = (params) => {
    const [indicator, setIndicator] = useState(null);
    const [data1, setData1] = useState(null);

    useEffect(() => {
      fetch(`/api/indicators/byobjectiveid/${params.item.id}`)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else throw Error("");
        })
        .then((data) => {
          setData1(data);
        })
        .catch((e) => {});
    }, [params.item]);

    function deleteUser() {
      setLoading(true);
      fetch(`/api/objectives/${params.item.id}`, {
        method: "DELETE",
        credentials: "include",
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else throw Error("");
        })
        .then((data) => {
          setLoading(false);
          props.setRefresh(!props.refresh);
        })
        .catch((e) => {
          setLoading(false);
        });
    }

    return (
      <div className="u_list">
        <i
          onClick={() => {
            deleteUser();
          }}
          className="fa fa-times"
        ></i>
        <p>
          <b>{params.item.Objective}</b>
        </p>
        <p>{params.item.Description}</p>
        <p>
          End Date:
          {params.item.Date}
        </p>
        <hr />
        {data1 && data1.length > 0 && (
          <>
            <h5>Indicators</h5>
            {data1.map((item, i) => {
              return (
                <Indicator
                  key={i}
                  index={i}
                  item={item}
                  refresh={props.refresh}
                  setRefresh={props.setRefresh}
                />
              );
            })}
          </>
        )}
        <button
          onClick={() => {
            setIndicator(true);
          }}
          className="addi"
        >
          Add Indicator
        </button>

        {indicator && (
          <NewIndicator
            id={params.item.id}
            ProjectID={params.item.ProjectID}
            setIndicator={setIndicator}
            refresh={props.refresh}
            setRefresh={props.setRefresh}
          />
        )}
      </div>
    );
  };

  return (
    <div className="div2equal">
      <form
        autoComplete="none"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <h3>Add Project Milestone</h3>
        <hr />
        <Input ref={obj} type="text" placeholder="" label="Milestone *" />
        <div className="div2equal">
          <Input
            ref={bgt}
            type="number"
            placeholder=""
            label="Milestone Budget (KSh) *"
          />
          <Input ref={dt} type="date" label="End Date *" />
        </div>

        <h6>{error}</h6>
        <br />
        <button
          onClick={() => {
            createDocument();
          }}
        >
          Submit
        </button>
      </form>
      <div className="customers">
        <h3>Project Milestones</h3>
        <hr />
        {data &&
          data.map((item, i) => {
            return <UserList key={i} item={item} index={i} />;
          })}
        <br />
        <br />
        <br />
        {data && data.length > 0 && (
          <button
            style={{ backgroundColor: "orange" }}
            onClick={() => {
              localStorage.setItem("step2", true);
              props.setRefresh(!props.refresh);
            }}
          >
            Next
          </button>
        )}
      </div>
      {loading && <WaveLoading />}
    </div>
  );
};

const Step3 = (props) => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [index, setIndex] = useState(0);
  const [index2, setIndex2] = useState(0);
  const [index3, setIndex3] = useState(0);
  const [dpts, setDpts] = useState([]);
  const [wards, setWards] = useState([]);
  const [units, setUnits] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [subsectors, setSubSectors] = useState([]);
  const [classifications, setClassifications] = useState([]);
  const [subClassifications, setSubClassifications] = useState([]);
  const [addwrd, setAddWrd] = useState(false);
  const dpt = useRef();
  const cls = useRef();
  const scls = useRef();
  const un = useRef();
  const sc = useRef();
  const cat = useRef();
  const fd = useRef();
  const [checks, setChecks] = useState([]);
  const [body, setBody] = useState({
    pid: null,
    Department: null,
    Classification: null,
    SubClassification: null,
    ClassificationTarget: null,
    Funding: null,
    Unit: null,
    SubSectors: null,
  });

  useEffect(() => {
    if (body.pid) {
      fetch(`/api/wards/byprojectid/${body.pid}`)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else throw Error("");
        })
        .then((data) => {
          setWards(data);
        })
        .catch((e) => {});
    }
  }, [refresh, body]);

  useEffect(() => {
    const token = localStorage.getItem("project");
    if (token != undefined) {
      let d = body;
      d.pid = token;
      setBody(d);
      setRefresh(!refresh);
    }
  }, []);

  useEffect(() => {
    fetch(`/api/departments`)
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else throw Error("");
      })
      .then((data) => {
        setDpts(data);
        let d = body;
        d.Department = data[index].Department;
        setBody(d);
        setUnits(data[index].Units);
      })
      .catch((e) => {});
  }, []);

  useEffect(() => {
    fetch(`/api/sectors`)
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else throw Error("");
      })
      .then((data) => {
        setSectors(data);
        setSubSectors(data[index3].SubSectors);
      })
      .catch((e) => {});
  }, []);

  useEffect(() => {
    if (dpts.length > 0) {
      setIndex2(0);
      fetch(`/api/classifications/bydepartment/${dpts[index].id}`)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else throw Error("");
        })
        .then((data) => {
          setClassifications(data);
          setSubClassifications(data[index2].Classes);
          let d = body;
          d.Classification = data[index2].Classification;
          setBody(d);
        })
        .catch((e) => {});
    }
  }, [dpts.length, index]);

  const createDocument = (e) => {
    setError("");
    let d = body;
    d.Unit = un.current.value;
    d.ClassificationTarget = cat.current.value;
    d.Funding = fd.current.value;
    d.SubClassification = scls.current.value;
    d.SubSectors = checks;
    setBody(d);

    const chck = Object.values(d);
    let valid = true;
    chck.map((item) => {
      if (item === "" || item == null) {
        valid = false;
      }
    });
    if (!valid) return setError("All fields are required!");

    fetch(`/api/projects/${d.pid}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(d),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else throw Error("");
      })
      .then((data) => {
        setLoading(false);
        if (data.success) {
          setError(data.success);

          setTimeout(() => {
            localStorage.removeItem("project");
            localStorage.removeItem("step2");
            window.location.href = "/projects/List";
          }, 1000);
        } else {
          setError(data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        setError("Oops! Something went wrong!");
      });
  };

  const List = (params) => {
    return (
      <div className="ss_list">
        <p>{params.item}</p>
        <i
          onClick={() => {
            setChecks(checks.filter((a) => a !== params.item));
          }}
          className="fa fa-times"
        ></i>
      </div>
    );
  };

  const Ward = (params) => {
    return (
      <div className="wrd">
        <p>
          <b>{params.sbc}: </b> {params.txt}
        </p>
      </div>
    );
  };

  return (
    <div>
      <div className="div2equal">
        <MySelect
          label="Department"
          setChanged={(e) => {
            setIndex(e.target.selectedIndex);
            setUnits(dpts[e.target.selectedIndex].Units);
            let d = body;
            d.Department = e.target.value;
            setBody(d);
          }}
          column="Department"
          ref={dpt}
          data={dpts}
        />
        <Select ref={un} label="Unit" data={units} />
      </div>
      <div className="div2equal">
        <MySelect
          label="Classification"
          column="Classification"
          ref={cls}
          data={classifications}
          setChanged={(e) => {
            setIndex2(e.target.selectedIndex);
            setSubClassifications(
              classifications[e.target.selectedIndex].Classes
            );
            let d = body;
            d.Classification = e.target.value;
            setBody(d);
          }}
        />
        <Select
          ref={scls}
          label="Sub Classification"
          data={subClassifications}
        />
      </div>
      <div className="div2equal">
        <Select
          ref={cat}
          label="Project Allocation"
          data={["Regular", "Youth", "Women", "People with Disability"]}
        />
        <Select
          ref={fd}
          label="Funding"
          data={[
            "Internal Funding",
            "Donors",
            "Partners",
            "National Government",
            "Community",
          ]}
        />
      </div>
      <h4>Project Sectors</h4>
      <hr />
      <div className="div2equal">
        <MySelect
          label="Sector"
          column="Sector"
          data={sectors}
          setChanged={(e) => {
            setIndex3(e.target.selectedIndex);
            setSubSectors(sectors[e.target.selectedIndex].SubSectors);
          }}
        />
        <Select
          ref={sc}
          label="Sub Sector"
          setChanged={(e) => {
            setChecks([...checks, e]);
          }}
          data={subsectors}
        />
      </div>
      <div className="sectors">
        {checks &&
          checks.map((item, i) => {
            return <List key={i} index={i} item={item} />;
          })}
      </div>
      <div className="location">
        <h4>Project Location</h4>
        <hr />
        <h3>Wards</h3>
        <div className="loclist">
          {wards.map((item, i) => {
            return <Ward key={i} sbc={item.SubCounty} txt={item.Ward} />;
          })}
        </div>
        <i
          onClick={() => {
            setAddWrd(true);
          }}
          className="fa fa-plus"
        ></i>
      </div>

      <h6>{error}</h6>
      <button
        onClick={() => {
          createDocument();
        }}
      >
        Submit
      </button>
      {addwrd && (
        <WardsPopup
          id={body?.pid}
          setLoading={setLoading}
          refresh={refresh}
          setRefresh={setRefresh}
          wards={wards}
          setWards={setWards}
          setAddWrd={setAddWrd}
        />
      )}
      {loading && <WaveLoading />}
    </div>
  );
};

const MySelect = (props) => {
  const [list, setList] = useState([]);
  useEffect(() => {
    if (props.data && props.data.length > 0) {
      let d = [];
      props.data.map((item) => {
        d.push(item[props.column]);
      });
      setList(d);
    }
  }, [props.data]);
  return (
    <div className="select-container">
      <label className={`label up`} htmlFor="input">
        {props.label}
      </label>
      <select
        onChange={(e) => {
          if (props.setChanged) {
            props.setChanged(e);
          }
        }}
        name="select"
      >
        {list.length > 0 &&
          list.map((item, i) => {
            return (
              <option key={i} value={item}>
                {item}
              </option>
            );
          })}
      </select>
    </div>
  );
};

const NewIndicator = (props) => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(null);
  const [data, setData] = useState(null);
  const [adding, setAdding] = useState(false);
  const [checks, setChecks] = useState([]);
  const [body, setBody] = useState({
    ObjectiveID: props.id,
    ProjectID: props.ProjectID,
    Name: null,
    Target: null,
    Value: null,
    Units: null,
  });
  const nm = useRef();
  const trg = useRef();
  const vl = useRef();
  const un = useRef();

  const createDocument = (e) => {
    setError("");
    let d = body;
    d.Name = nm.current.value;
    d.Target = trg.current.value;
    d.Value = vl.current.value;
    d.Units = un.current.value;
    const chck = Object.values(d);
    let valid = true;
    chck.map((item) => {
      if (item == "" || item == null || item == []) {
        valid = false;
      }
    });
    if (!valid) return setError("All fields are required!");

    setLoading(true);

    fetch("/api/indicators/create", {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(d),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else throw Error("");
      })
      .then((data) => {
        setLoading(false);
        if (data.success) {
          setError(data.success);
          setTimeout(() => {
            props.setIndicator(null);
            props.setRefresh(!props.refresh);
          }, 500);
        } else {
          setError(data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        setError("Oops! Something went wrong!");
      });
  };

  return (
    <div className="npopup">
      <div className="pcontainer">
        <h3>New Indicator</h3>
        <i
          onClick={() => {
            props.setIndicator(false);
          }}
          className="fa fa-times"
        ></i>
        <hr />
        <div className="content">
          <Input ref={nm} type="text" label="Indicator *" />
          <Select
            ref={un}
            label="Unit *"
            data={[
              "KG",
              "KM",
              "Metres",
              "Litres",
              "Number",
              "Tonnes",
              "Percentage",
            ]}
          />
          <div className="div2equal">
            <Input ref={trg} type="number" label="Target *" />
            <Input ref={vl} type="number" label="Current Value *" />
          </div>

          <h6>{error}</h6>
          <button
            onClick={() => {
              createDocument();
            }}
          >
            Submit
          </button>
        </div>
        {loading && <WaveLoading />}
      </div>
    </div>
  );
};

const Indicator = (props) => {
  const [loading, setLoading] = useState(null);
  function deleteUser() {
    setLoading(true);
    fetch(`/api/indicators/${props.item.id}`, {
      method: "DELETE",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else throw Error("");
      })
      .then((data) => {
        setLoading(false);
        props.setRefresh(!props.refresh);
      })
      .catch((e) => {
        setLoading(false);
      });
  }
  return (
    <div className="indicator">
      <i
        onClick={() => {
          deleteUser();
        }}
        className="fa fa-times"
      ></i>
      <p>
        {props.index + 1}. {props.item.Name} - {props.item.Value}/
        {props.item.Target}
      </p>
    </div>
  );
};

const WardsPopup = (props) => {
  const address = {
    "Mwingi North": ["Ngomeni", "Kyuso", "Mumoni", "Tseikuru", "Tharaka"],
    "Mwingi West": ["Kyome/Thaana", "Nguutani", "Migwani", "Kiomo/Kyethani"],
    "Mwingi Central": ["Central", "Kivou", "Nguni", "Nuu", "Mui", "Waita"],
    "Kitui West": ["Mutonguni", "Kauwi", "Matinyani", "Kwa Mutonga/Kithumula"],
    "Kitui Rural": ["Kisasi", "Mbitini", "Kwavonza/Yatta", "Kanyangi"],
    "Kitui Central": [
      "Miambani",
      "Township Kyangwithya West",
      "Mulango",
      "Kyangwithya East",
    ],
    "Kitui East": [
      "Zombe/Mwitika",
      "Chuluni",
      "Voo/Kyamatu",
      "Endau/Malalani",
      "Mutito/Kaliku",
    ],
    "Kitui South": [
      "Ikana/Kyantune",
      "Mutomo",
      "Mutha",
      "Ikutha",
      "Kanziko",
      "Athi",
    ],
  };
  const [error, setError] = useState("");

  const chks = Object.keys(address);

  const Ward = (params) => {
    const [active, setActive] = useState(false);
    useEffect(() => {
      let el = props?.wards?.find((item) => item?.Ward == params.txt);
      if (el) {
        setActive(true);
      } else setActive(false);
    }, [props.wards, props.refresh]);

    function createWards() {
      props.setLoading(true);
      fetch("/api/wards/create", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          ProjectID: props.id,
          Ward: params.txt,
          SubCounty: params.sb,
        }),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else throw Error("");
        })
        .then((data) => {
          props.setLoading(false);
          if (data.success) {
            setError(data.success);
            props.setRefresh(!props.refresh);
          } else {
            setError(data.error);
          }
        })
        .catch((err) => {
          props.setLoading(false);
          setError("Oops! Something went wrong!");
        });
    }
    function deleteWards() {
      let el = props?.wards?.find((item) => item?.Ward == params.txt);
      let d = props.wards.filter((item) => item !== el);
      props.setWards(d);
      props.setLoading(true);
      fetch(`/api/wards/${el.id}`, {
        method: "DELETE",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else throw Error("");
        })
        .then((data) => {
          props.setLoading(false);
          props.setRefresh(!props.refresh);
          if (data.success) {
            setError(data.success);
            setTimeout(() => {
              props.setRefresh(!props.refresh);
            }, 1000);
          } else {
            setError(data.error);
          }
        })
        .catch((err) => {
          props.setLoading(false);
          setError("Oops! Something went wrong!");
        });
    }
    return (
      <div
        onClick={() => {
          if (active) deleteWards();
          else {
            createWards();
          }
        }}
        className={active ? "wrd-active" : "wrd"}
      >
        <p>{params.txt}</p>{" "}
        <i className={active ? "fa fa-times" : "fa fa-plus"}></i>
      </div>
    );
  };

  return (
    <div className="wrdpopup">
      <div className="wrdwrap">
        <div className="top">
          <h4>Select wards</h4>
          <i
            onClick={() => {
              props.setAddWrd(false);
            }}
            className="fa fa-times"
          ></i>
        </div>
        <h6>{error}</h6>
        <div className="sections">
          {chks.map((item, i) => {
            return (
              <div key={i} className="section">
                <h4>{item}</h4>
                <div className="wrdlist">
                  {address[item].map((it, ii) => {
                    return <Ward txt={it} sb={item} />;
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
