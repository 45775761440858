import React from "react";
import { useState, useEffect } from "react";
import "../../Styles/userhome.scss";
import PortalUsers from "./PortalUsers";
import MUsers from "./MUsers";

export default function UserHome(props) {
  const [active, setActive] = useState("Portal Users");

  return (
    <div className="userhome">
      <div className="vtop">
        <Item txt="Portal Users" active={active} setActive={setActive} />
        <Item txt="Mobile Users" active={active} setActive={setActive} />
      </div>
      {active == "Portal Users" && <PortalUsers />}
      {active == "Mobile Users" && <MUsers />}
    </div>
  );
}

const Item = (props) => {
  return (
    <div
      onClick={() => {
        props.setActive(props.txt);
      }}
      className={props.active == props.txt ? "vactive" : "vitem"}
    >
      <p>{props.txt}</p>
    </div>
  );
};
