import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "../Util/Select";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useRef, useState } from "react";
import html2canvas from "html2canvas";

export default function Tables(props) {
  return (
    <div>
      <DistributionByWards />
      <DistributionBySubC />
    </div>
  );
}

const DistributionByWards = (props) => {
  const [data, setData] = useState(null);
  const tb = useRef();

  useEffect(() => {
    fetch(`/api/reports/nrw/performance`)
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw Error("");
        }
      })
      .then((data) => {
   
        setData(data);
      })
      .catch((e) => {});
  }, []);

  const RowItem = (params) => {
    return (
      <tr>
        <td>{params.item?.County}</td>
        <td className="cc">{params.item?.Baseline}</td>
        <td className="cc">{params.item?.Q1 ? params.item?.Q1 : 0}</td>
        <td className="cc">{params.item?.Q2 ? params.item?.Q2 : 0}</td>
        <td className="cc">{params.item?.Q3 ? params.item?.Q3 : 0}</td>
        <td className="cc">{params.item?.Q4 ? params.item?.Q4 : 0}</td>
        <td className="cc">
          {params.item?.Average ? params.item?.Average : 0}
        </td>
        <td className="cc">{params.item?.Change ? params.item?.Change : 0}</td>
      </tr>
    );
  };

  return (
    <div className="table">
      <div className="div1auto">
        <h1>Project Distribution by Ward</h1>
        <div className="filter">
          <Select data={["2023", "2024", "2025", "2026", "2027"]} />
          <FontAwesomeIcon
            onClick={() => {
              exportToPDF("Project Distribution by Ward", tb.current);
            }}
            className="download"
            icon={faDownload}
          />
        </div>
      </div>
      <table ref={tb}>
        <thead>
          <tr>
            <th>No.</th>
            <th>Sub County</th>
            <th>Ward</th>
            <th>Budget</th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data?.data?.map((item, i) => {
              return <RowItem key={i} item={item} />;
            })}
        </tbody>
      </table>
    </div>
  );
};

const DistributionBySubC = (props) => {
  const [data, setData] = useState(null);
  const tb = useRef();

  useEffect(() => {
    fetch(`/api/reports/nrw/performance`)
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw Error("");
        }
      })
      .then((data) => {

        setData(data);
      })
      .catch((e) => {});
  }, []);

  const RowItem = (params) => {
    return (
      <tr>
        <td>{params.item?.County}</td>
        <td className="cc">{params.item?.Baseline}</td>
        <td className="cc">{params.item?.Q1 ? params.item?.Q1 : 0}</td>
        <td className="cc">{params.item?.Q2 ? params.item?.Q2 : 0}</td>
        <td className="cc">{params.item?.Q3 ? params.item?.Q3 : 0}</td>
        <td className="cc">{params.item?.Q4 ? params.item?.Q4 : 0}</td>
        <td className="cc">
          {params.item?.Average ? params.item?.Average : 0}
        </td>
        <td className="cc">{params.item?.Change ? params.item?.Change : 0}</td>
      </tr>
    );
  };

  return (
    <div className="table">
      <div className="div1auto">
        <h1>Budget Distribution by Sub County</h1>
        <div className="filter">
          <Select data={["2023", "2024", "2025", "2026", "2027"]} />
          <FontAwesomeIcon
            onClick={() => {
              exportToPDF("Project Distribution by Sub County", tb.current);
            }}
            className="download"
            icon={faDownload}
          />
        </div>
      </div>
      <table ref={tb}>
        <thead>
          <tr>
            <th>No.</th>
            <th>Sub County</th>
            <th>Budget</th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data?.data?.map((item, i) => {
              return <RowItem key={i} item={item} />;
            })}
        </tbody>
      </table>
    </div>
  );
};

const exportToPDF = (title, ref) => {
  html2canvas(ref).then((canvas) => {
    const pngUrl = canvas.toDataURL("image/png");
    const downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `${title}.png`;
    downloadLink.click();
  });
};
