import { useRef, useState } from "react";
import "../../Styles/workplan.scss";
import { useEffect } from "react";
import WaveLoading from "../Util/WaveLoading";
import Input from "../Util/Input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

export default function CreateWorkPlan(props) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [fo, setFO] = useState(null);
  const [prg, setPrg] = useState(null);
  const [prgdata, setPrgData] = useState(null);
  const [fodata, setFOData] = useState(null);
  const [su, setSU] = useState(null);
  const [sudata, setSUData] = useState(null);
  const [ind, setInd] = useState(null);
  const [showing, setShowing] = useState(true);
  const [indicators, setIndicators] = useState([]);

  const dt = useRef();

  const [body, setBody] = useState({
    UserID: null,
    SupervisorID: null,
    Date: null,
  });

  const handleResize = () => {
    if (window.innerWidth < 768) {
      setShowing(false);
    } else {
      setShowing(true);
    }
  };

  useEffect(() => {
    handleResize();
  }, []);

  useEffect(() => {
    if (prgdata) {
      fetch(`/api/indicators/byprojectid/${prgdata.id}`)
        .then((res) => {
          if (res.ok) return res.json();
          else throw Error("");
        })
        .then((data) => {
          setInd(data);
        })
        .catch((e) => {});
    }
  }, [prgdata]);

  function searchByPhone(role, q) {
    fetch(`/api/users/search/phone/${role}/${q}`)
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else throw Error();
      })
      .then((data) => {
        if (role === "Supervisor") {
          setSU(data);
        } else {
          setFO(data);
        }
      })
      .catch((e) => {});
  }

  function searchByProject(q) {
    fetch(`/api/projects/seachbyproject/${q}`)
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else throw Error();
      })
      .then((data) => {
        setPrg(data);
      })
      .catch((e) => {});
  }

  const createDocument = (e) => {
    setError("");
    let d = body;
    d.Date = dt.current.value;
    const chck = Object.values(body);
    let valid = true;
    chck.map((item) => {
      if (item === null || item === "") {
        valid = false;
      }
    });
    if (!valid) return setError("All fields are required!");

    if (indicators.length == 0) return setError("No indicator selected!");

    indicators.map((item, i) => {
      setLoading(true);
      let d = body;
      d.IndicatorID = item;

      fetch("/api/workplan/create", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(d),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else throw Error("");
        })
        .then((data) => {
          if (i == indicators.length - 1) {
            setLoading(false);
            if (data.success) {
              setError(data.success);
              setTimeout(() => {
                props.setCreateWP(false);
              }, 1000);
            } else {
              setError(data.error);
            }
          } else {
            if (data.success) {
              setError(data.success);
            } else {
              setError(data.error);
            }
          }
        })
        .catch((err) => {
          if (i == indicators.length - 1) {
            setError("Oops! Something went wrong!");
            setLoading(false);
          } else setError("Oops! Something went wrong!");
        });
    });
    setLoading(false);
  };

  const PrgList = (params) => {
    return (
      <div
        onClick={() => {
          setPrgData(params.item);
          setPrg(null);
        }}
        className="s_list"
      >
        <p>
          {params.item.ProjectName} - Tender No. {params.item.TenderNo}
        </p>
      </div>
    );
  };

  const List = (params) => {
    return (
      <div
        onClick={() => {
          if (params.type === "fo") {
            let d = body;
            d.UserID = params.item.UserID;
            setBody(d);
            setFOData(params.item);
            setFO(null);
          } else {
            let d = body;
            d.SupervisorID = params.item.UserID;
            setBody(d);
            setSUData(params.item);
            setSU(null);
          }
        }}
        className="s_list"
      >
        <p>
          {params.item.Name} - {params.item.Phone}
        </p>
      </div>
    );
  };

  const Indicator = (params) => {
    const [checked, setChecked] = useState(params.checked);
    useEffect(() => {
      const i = indicators
        .map((e) => {
          return e;
        })
        .indexOf(params.item.id);

      if (i != -1) {
        setChecked(true);
      } else {
        setChecked(false);
      }
    }, [indicators]);

    return (
      <div onClick={() => {}} className="ind">
        <div>
          <input
            onChange={(e) => {
              setChecked(e.target.checked);
              if (e.target.checked) {
                let d = indicators;
                d.push(params.item.id);
                setIndicators(d);
              } else {
                setIndicators(indicators.filter((a) => a !== params.item.id));
              }
            }}
            checked={checked}
            type="checkbox"
            name=""
            id=""
          />
          <h3>{params.item.Objective}</h3>
        </div>

        <p>
          <b>Indicator: </b> {params.item.Name} <b>Current Value: </b>
          {params.item.Value} <b>Target: </b>
          {params.item.Target}
        </p>
      </div>
    );
  };

  return (
    <div className="workplan">
      <div className="container">
        <div className="createworkplan">
          <div className="cpcontainer">
            <div className="tp">
              <h3>Work Plan Item</h3>
              <FontAwesomeIcon
                onClick={() => {
                  props.setCreateWP(false);
                }}
                icon={faTimes}
              />
            </div>

            <div className="bdy">
              <form
                autoComplete="none"
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <div className="bdy section div2equal">
                  <div className="search">
                    <Input
                      type="text"
                      handleChange={(e) => {
                        if (e.length > 2) {
                          searchByProject(e);
                        } else if (e == "") {
                          setPrg(null);
                        }
                      }}
                      label="Project Name"
                      hint="Type to search"
                    />
                    {prg && prg.length > 0 && (
                      <div className="search_list">
                        {prg.map((item, i) => {
                          return <PrgList key={i} item={item} />;
                        })}
                      </div>
                    )}
                  </div>
                  <div>
                    {prgdata && (
                      <PrgItem
                        title="Selected Project"
                        clearEvent={() => {
                          setPrgData(null);
                        }}
                        data={prgdata}
                      />
                    )}
                  </div>
                </div>

                <br></br>
                <div className="section">
                  <h3>Project Indicators</h3>
                  <hr />
                  <div className="prg_list">
                    {ind &&
                      ind.length > 0 &&
                      ind.map((item, i) => {
                        return <Indicator key={i} item={item} />;
                      })}
                  </div>
                </div>

                <div className="section">
                  <h3>Select Field Officer *</h3>
                  <hr />
                  <div className="div2equal">
                    <div className="search">
                      <Input
                        type="text"
                        handleChange={(e) => {
                          searchByPhone("Field Officer", e);
                        }}
                        label="Name"
                        hint="Type to search"
                      />
                      {fo && (
                        <div className="search_list">
                          {fo.map((item, i) => {
                            return <List key={i} item={item} type="fo" />;
                          })}
                        </div>
                      )}
                    </div>

                    <div>
                      {fodata && (
                        <Item
                          title="Selected Field Officer"
                          clearEvent={() => {
                            let d = body;
                            d.UserID = null;
                            setBody(d);
                            setFOData(null);
                          }}
                          data={fodata}
                        />
                      )}
                    </div>
                  </div>
                </div>

                <div className="section">
                  <h3>Assign Supervisor *</h3>
                  <hr />
                  <div className="div2equal">
                    <div className="search">
                      <Input
                        type="text"
                        handleChange={(e) => {
                          searchByPhone("Supervisor", e);
                        }}
                        label="Name"
                        hint="Type to search"
                      />
                      {su && (
                        <div className="search_list">
                          {su.map((item, i) => {
                            return <List key={i} item={item} type="su" />;
                          })}
                        </div>
                      )}
                    </div>

                    <div>
                      {sudata && (
                        <Item
                          title="Selected Supervisor"
                          clearEvent={() => {
                            let d = body;
                            d.SupervisorID = null;
                            setBody(d);
                            setSUData(null);
                          }}
                          data={sudata}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <Input type="date" ref={dt} label="Date" />
                <h6>{error}</h6>
                <button
                  onClick={() => {
                    createDocument();
                  }}
                >
                  Submit
                </button>
              </form>
              {loading && <WaveLoading />}
            </div>
          </div>
        </div>{" "}
      </div>
    </div>
  );
}

const Item = (props) => {
  return (
    <>
      {props.data && (
        <div className="item">
          <div>
            <h3>{props.title}</h3>
            <FontAwesomeIcon
              onClick={() => {
                props.clearEvent();
              }}
              icon={faTimes}
            />
          </div>
          <hr />
          <p>{props.data.Name}</p>
          <p>{props.data.Phone}</p>
        </div>
      )}
    </>
  );
};

const PrgItem = (props) => {
  return (
    <>
      {props.data && (
        <div className="item">
          <div>
            <h3>{props.title}</h3>
            <FontAwesomeIcon
              onClick={() => {
                props.clearEvent();
              }}
              icon={faTimes}
            />
          </div>

          <hr />
          <p>{props.data.ProjectName}</p>
          <p>Tender No. {props.data.TenderNo}</p>
        </div>
      )}
    </>
  );
};
