import React, { useEffect, useState } from "react";
import GaugeChart from "react-gauge-chart";
import { FaMapMarked } from "react-icons/fa";
import { FaMoneyBillTrendUp, FaScroll } from "react-icons/fa6";
import { FiRefreshCcw } from "react-icons/fi";
import { MdAttachMoney } from "react-icons/md";
import "../../Styles/stats.scss";
import Pagination from "../Util/Pagination";
import WaveLoading from "../Util/WaveLoading";
import CustomBarChart from "./CustomBarChart";

export default function WPStats(props) {
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);

  function getData() {
    setLoading(true);
    fetch(`/api/projects/topstats`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("Failed!");
      })
      .then((data) => {
        setData(data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  function withCommas(x) {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="stats">
      <div className="welcome">
        <div>
          <h1>Dashboard</h1>
        </div>
        <FiRefreshCcw
          onClick={() => {
            setRefresh(!refresh);
          }}
          className="refresh"
        />
      </div>

      <div className="taskstats">
        <div className="left">
          <div className="bar">
            <div></div>
            <h2>Projects Budget</h2>
          </div>

          <div className="outer">
            <div className="ll">
              <div className="section">
                <div className="single">
                  <FaMoneyBillTrendUp className="ts" />
                  <div>
                    <h4>{data ? withCommas(data?.Budget2024) : 0}</h4>
                    <p>{new Date().getFullYear()}</p>
                  </div>
                </div>
              </div>
              <div className="section">
                <div className="single">
                  <FaMoneyBillTrendUp className="ts" />
                  <div>
                    <h4>{data ? withCommas(data?.Budget2023) : 0}</h4>
                    <p>{new Date().getFullYear() - 1}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="section divrow">
              <h3>Source of Funding</h3>
              <div className="my-gauge">
                <CustomBarChart
                  aspect={1.5}
                  data={data ? data.Funding : null}
                  color="orange"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="left">
          <div className="bar">
            <div></div>
            <h2>Project Status</h2>
          </div>

          <div className="outer">
            <div className="ll">
              <div className="section">
                <div className="single">
                  <FaMoneyBillTrendUp className="ts" />
                  <div>
                    <h4>{data ? data?.Total : 0}</h4>
                    <p>Total Projects</p>
                  </div>
                </div>
              </div>
              <div className="section">
                <div className="single">
                  <MdAttachMoney className="ts" />
                  <div>
                    <h4>{data ? data?.Complete : 0}</h4>
                    <p>Complete Projects</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="section divrow">
              <h3>Completion Rate</h3>
              <div className="my-gauge">
                <GaugeChart
                  className="gauge"
                  id="gauge-chart2"
                  nrOfLevels={3}
                  colors={["#FF407D", "#40679E", "#1B3C73"]}
                  arcWidth={0.3}
                  percent={
                    data
                      ? (
                          parseInt(data?.Complete) / parseInt(data?.Total)
                        ).toFixed(2)
                      : 0
                  }
                  textColor="gray"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="taskstats">
        <div className="left">
          <div className="bar">
            <div></div>
            <h2>Subcounty Distribution</h2>
          </div>
          <div className="section">
            <CustomBarChart
              aspect={1.5}
              data={data ? data.SubCounty : null}
              color="orange"
            />
          </div>
        </div>
        <div className="right">
          <div className="bar">
            <div></div>
            <h2>Ward Distribution</h2>
          </div>

          <div className="section">
            <CustomBarChart
              aspect={1.5}
              data={data ? data.Ward : null}
              color="orange"
            />
          </div>
        </div>
      </div>

      {loading && <WaveLoading />}
      {data?.Tasks && (
        <Pagination
          totalItems={data?.total}
          onPageChange={(v) => {
            setCurrentPage(v);
          }}
          currentPage={currentPage}
        />
      )}
    </div>
  );
}

const Item = (props) => {
  const [color, setColor] = useState("#fff");
  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    if ((props.index + 1) % 2 == 0) {
      setColor("#feece2");
    }
  }, []);

  return (
    <>
      <div
        className="row"
        style={{ backgroundColor: color }}
        onClick={() => setClicked(true)}
      >
        <p>{props.index + 1}</p>
        <p>{props.item.Name}</p>
        <p>{props.item.Task}</p>
        <p>{props.item.Description}</p>
        <p>{props.item.Email}</p>
        <p>{props.item.Phone}</p>
        <p>{props.item.Level}</p>
        <p>{props.item.LevelName}</p>
      </div>

      {/* {clicked && <PopUp setClicked={setClicked} item={props.item} />} */}
    </>
  );
};
