import "../../Styles/gis.scss";
import { useEffect } from "react";
import { useState } from "react";
import Map from "ol/Map";
import View from "ol/View";
import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import { Vector as VectorSource } from "ol/source";
import { Circle as CircleStyle, Fill, Stroke, Style } from "ol/style";
import { useRef } from "react";
import XYZ from "ol/source/XYZ";
import RippleLoading from "../Util/RippleLoading";
import Draw, { createBox } from "ol/interaction/Draw";
import GeoJSON from "ol/format/GeoJSON";
import html2canvas from "html2canvas";
import Graticule from "ol/layer/Graticule";
import Basemaps from "./Basemaps";
import RightPanel from "./RightPanel";
import {
  ScaleLine,
  Zoom,
  ZoomToExtent,
  defaults as defaultControls,
} from "ol/control";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faTimes } from "@fortawesome/free-solid-svg-icons";
import Loading from "../Util/Loading";
import Input from "../Util/Input";
import northArrow from "../../assets/imgs/north.png";
import Popup from "./Popup.jsx";
import bn from "../../assets/imgs/bn.png";
import jsPDF from "jspdf";

export default function MyMap(props) {
  const { myData } = require("../../assets/data/data.js");
  const { colors } = require("../../assets/data/data.js");
  const [active, setActive] = useState("Results");
  const [filter, setFilter] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dataUrl, setDataUrl] = useState(null);
  const [activeVis, setActiveVis] = useState("Normal");
  const [visualization, setVisualization] = useState([]);
  const [projects, setProjects] = useState(
    new VectorLayer({ title: "Projects" })
  );
  const [towns, setTowns] = useState(
    new VectorLayer({ title: "Towns", declutter: true })
  );
  const [boreholes, setBoreholes] = useState(
    new VectorLayer({ title: "Boreholes", declutter: true })
  );
  const [factories, setFactories] = useState(
    new VectorLayer({ title: "Factories", declutter: true })
  );
  const [hospitals, setHospitals] = useState(
    new VectorLayer({ title: "Hospitals", declutter: true })
  );
  const [industries, setIndustries] = useState(
    new VectorLayer({ title: "Industries", declutter: true })
  );
  const [schools, setSchools] = useState(
    new VectorLayer({ title: "Schools", declutter: true })
  );
  const [subcounty, setSubCounty] = useState(
    new VectorLayer({ title: "Sub Counties" })
  );
  var source1 = new VectorSource();
  const [drawlayer, setDrawLayer] = useState(
    new VectorLayer({ title: "Draw", source: source1 })
  );
  const [selected, setSelected] = useState(0);
  const [ward, setWard] = useState(new VectorLayer({ title: "Wards" }));
  const [data, setData] = useState([]);
  const [basemap, setBasemap] = useState(new TileLayer({ title: "Basemap" }));
  const [graticule, setGraticule] = useState(
    new Graticule({
      strokeStyle: new Stroke({
        color: "rgba(0,0,0,0.5)",
        width: 2,
        lineDash: [0.5, 8],
      }),
      showLabels: true,
      wrapX: false,
      title: "Grid",
    })
  );
  const [scale, setScale] = useState("");
  const [controls, setControls] = useState([]);
  const [year, setYear] = useState("2023");
  const [activeF, setActiveF] = useState(year);
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [popup, setPopup] = useState(null);
  const [dowloading, setDownloading] = useState(false);
  const [unique, setUnique] = useState(["Results", "TAs", "Activities"]);
  let uniquex = ["Results", "TAs", "Activities"];
  const [isLoading, setIsLoading] = useState(false);
  const [mapExport, setMapExport] = useState(false);
  const [baseSelector, setBaseSelector] = useState(null);
  const [extent, setExtent] = useState([
    37.5955910740807013, -3.0731311353971802, 39.0665389950774014,
    -0.0556890108975159,
  ]);
  //set initial state
  const [map, setMap] = useState(null);
  const mapElement = useRef();
  const exportmap = useRef();
  const mapRef = useRef();
  mapRef.current = map;
  var draw = new Draw({
    source: source1,
    type: "Circle",
    geometryFunction: createBox(),
  });

  const types = {
    Results: ["Normal", "StakeholderType", "TA_Provided", "TAThrough"],
    TAs: ["Normal", "BeneficiaryType", "TA_Provided", "TAThrough"],
    Activities: ["Normal", "ActivityType"],
  };

  useEffect(() => {
    basemap.setSource(
      new XYZ({
        url: myData[0].url,
        crossOrigin: "anonymous",
      })
    );
    const initialMap = new Map({
      target: mapElement.current,
      layers: [
        basemap,
        towns,
        schools,
        hospitals,
        boreholes,
        factories,
        industries,
        ward,
        subcounty,
        projects,
        graticule,
        drawlayer,
      ],
      view: new View({
        projection: "EPSG:4326",
        center: [38.3122, -0.43135],
        zoom: 12,
        maxZoom: 20,
      }),
      controls: defaultControls().extend([
        new ZoomToExtent({
          extent: extent,
        }),
        new ScaleLine({
          units: "metric",
          bar: false,
          text: "Scale",
        }),
      ]),
    });

    boreholes.setVisible(false);
    schools.setVisible(false);
    industries.setVisible(false);
    factories.setVisible(false);
    hospitals.setVisible(false);

    initialMap.on("dblclick", function (evt) {
      let features = [];
      initialMap.forEachFeatureAtPixel(
        evt.pixel,
        function (clickedFeature, layer) {
          const attributes = clickedFeature?.getProperties();
          const layerTitle = layer?.get("title");

          features.push({ Name: layerTitle, data: attributes });
        }
      );
      if (features.length > 0) {
        setPopup(features);
      }
    });

    setMap(initialMap);
    return () => {
      initialMap.setTarget(null);
    };
  }, []);

  useEffect(() => {
    if (map) {
      map.updateSize();
    }
  }, [props.fullscreen]);

  useEffect(() => {
    if (map) {
      const today = new Date();
      const lastDate = new Date(today.getFullYear(), today.getMonth() + 1, 1);
      setStart(`2023-01-01`);
      setEnd(lastDate.toISOString().split("T")[0]);
      fetchOtherLayers();
      fetchProjects();
    }
  }, [map]);

  useEffect(() => {
    if (map && filter.length > 0) {
      switch (active) {
        // case "Activities":
        //   fetchAll(arf, "arf", colors[0]);
        //   break;
        // case "Results":
        //   fetchAll(rmf, "rmf", colors[1]);
        //   break;
        // case "TAs":
        //   fetchAll(taf, "taf", colors[2]);
        //   break;
        default:
          break;
      }
    }
  }, [filter]);

  function fetchProjects() {
    setLoading(true);
    fetch(`/api/projects/geojson`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        console.log(data);
        if (data && data.length > 0) {
          setData(data);
          let points = [];
          data.map((e) => {
            let prp = e;
            delete prp.geom;
            let d = {
              type: "Feature",
              geometry: {
                type: "Point",
                coordinates: [e.Longitude, e.Latitude],
              },
              properties: prp,
            };
            points.push(d);
          });
          var geojsonData = {
            type: "FeatureCollection",
            features: points,
          };

          var geojsonSource = new VectorSource({
            features: new GeoJSON().readFeatures(geojsonData),
          });

          projects.setSource(geojsonSource);
          projects.setStyle(
            new Style({
              image: new CircleStyle({
                radius: 6,
                fill: new Fill({
                  color: "blue",
                }),
                stroke: new Stroke({
                  color: "blue",
                  width: 1,
                }),
              }),
              fill: new Fill({
                color: "blue",
              }),
              stroke: new Stroke({
                color: "blue",
                width: 1,
              }),
            })
          );
          var extent = geojsonSource.getExtent();

          map?.getView().fit(extent, { padding: [100, 100, 100, 100] });
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  }

  function fetchOtherLayers() {
    setLoading(true);
    fetch(`/api/geometry/layers/fetchothers`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        if (data?.Towns?.length > 0) {
          let points = [];
          data.Towns.map((e) => {
            let prp = e;
            const x = JSON.parse(e.geom);
            delete prp.geom;
            let d = {
              type: "Feature",
              geometry: x,
              properties: prp,
            };
            points.push(d);
          });
          var geojsonData = {
            type: "FeatureCollection",
            features: points,
          };

          var geojsonSource = new VectorSource({
            features: new GeoJSON().readFeatures(geojsonData),
          });
          towns.setSource(geojsonSource);
          towns.setStyle(
            new Style({
              image: new CircleStyle({
                radius: 6,
                fill: new Fill({
                  color: "wheat",
                }),
                stroke: new Stroke({
                  color: "red",
                  width: 2,
                }),
              }),
            })
          );
          setLoading(false);
        }
        if (data?.Boreholes?.length > 0) {
          let points = [];
          data.Boreholes.map((e) => {
            let prp = e;
            const x = JSON.parse(e.geom);
            delete prp.geom;
            let d = {
              type: "Feature",
              geometry: x,
              properties: prp,
            };
            points.push(d);
          });
          var geojsonData = {
            type: "FeatureCollection",
            features: points,
          };

          var geojsonSource = new VectorSource({
            features: new GeoJSON().readFeatures(geojsonData),
          });
          boreholes.setSource(geojsonSource);
          boreholes.setStyle(
            new Style({
              image: new CircleStyle({
                radius: 4,
                fill: new Fill({
                  color: "blue",
                }),
                stroke: new Stroke({
                  color: "yellow",
                  width: 1,
                }),
              }),
            })
          );
          setLoading(false);
        }
        if (data?.Hospitals?.length > 0) {
          let points = [];
          data.Hospitals.map((e) => {
            let prp = e;
            const x = JSON.parse(e.geom);
            delete prp.geom;
            let d = {
              type: "Feature",
              geometry: x,
              properties: prp,
            };
            points.push(d);
          });
          var geojsonData = {
            type: "FeatureCollection",
            features: points,
          };

          var geojsonSource = new VectorSource({
            features: new GeoJSON().readFeatures(geojsonData),
          });
          hospitals.setSource(geojsonSource);
          hospitals.setStyle(
            new Style({
              image: new CircleStyle({
                radius: 4,
                fill: new Fill({
                  color: "red",
                }),
                stroke: new Stroke({
                  color: "white",
                  width: 2,
                }),
              }),
            })
          );
          setLoading(false);
        }
        if (data?.Industries?.length > 0) {
          let points = [];
          data.Industries.map((e) => {
            let prp = e;
            const x = JSON.parse(e.geom);
            delete prp.geom;
            let d = {
              type: "Feature",
              geometry: x,
              properties: prp,
            };
            points.push(d);
          });
          var geojsonData = {
            type: "FeatureCollection",
            features: points,
          };

          var geojsonSource = new VectorSource({
            features: new GeoJSON().readFeatures(geojsonData),
          });
          industries.setSource(geojsonSource);
          industries.setStyle(
            new Style({
              image: new CircleStyle({
                radius: 4,
                fill: new Fill({
                  color: "brown",
                }),
                stroke: new Stroke({
                  color: "blue",
                  width: 2,
                }),
              }),
            })
          );
          setLoading(false);
        }
        if (data?.Factories?.length > 0) {
          let points = [];
          data.Factories.map((e) => {
            let prp = e;
            const x = JSON.parse(e.geom);
            delete prp.geom;
            let d = {
              type: "Feature",
              geometry: x,
              properties: prp,
            };
            points.push(d);
          });
          var geojsonData = {
            type: "FeatureCollection",
            features: points,
          };

          var geojsonSource = new VectorSource({
            features: new GeoJSON().readFeatures(geojsonData),
          });
          factories.setSource(geojsonSource);
          factories.setStyle(
            new Style({
              image: new CircleStyle({
                radius: 4,
                fill: new Fill({
                  color: "brown",
                }),
                stroke: new Stroke({
                  color: "orange",
                  width: 2,
                }),
              }),
            })
          );
          setLoading(false);
        }
        if (data?.Schools?.length > 0) {
          let points = [];
          data.Schools.map((e) => {
            let prp = e;
            const x = JSON.parse(e.geom);
            delete prp.geom;
            let d = {
              type: "Feature",
              geometry: x,
              properties: prp,
            };
            points.push(d);
          });
          var geojsonData = {
            type: "FeatureCollection",
            features: points,
          };

          var geojsonSource = new VectorSource({
            features: new GeoJSON().readFeatures(geojsonData),
          });
          schools.setSource(geojsonSource);
          schools.setStyle(
            new Style({
              image: new CircleStyle({
                radius: 4,
                fill: new Fill({
                  color: "yellow",
                }),
                stroke: new Stroke({
                  color: "black",
                  width: 2,
                }),
              }),
            })
          );
          setLoading(false);
        }
      })
      .catch((e) => {
        setLoading(false);
      });
  }

  useEffect(() => {
    if (map) {
      setLoading(true);
      fetch(`/api/geometry/counties/boundaries`)
        .then((res) => {
          if (res.ok) return res.json();
          else throw Error("");
        })
        .then((data) => {
          if (data?.SubCounty?.length > 0) {
            let points = [];
            data.SubCounty.map((e) => {
              let prp = e;

              const x = JSON.parse(e.geom);

              delete prp.geom;

              let d = {
                type: "Feature",
                geometry: x,
                properties: prp,
              };
              points.push(d);
            });
            var geojsonData = {
              type: "FeatureCollection",
              features: points,
            };

            var geojsonSource = new VectorSource({
              features: new GeoJSON().readFeatures(geojsonData),
            });

            subcounty.setSource(geojsonSource);
            subcounty.setStyle(
              new Style({
                fill: new Fill({
                  color: "transparent",
                }),
                stroke: new Stroke({
                  color: "purple",
                  width: 2,
                }),
              })
            );
            var extent = geojsonSource.getExtent();
            map?.getView().fit(extent, { padding: [100, 100, 100, 100] });
            setLoading(false);
          }
          if (data?.Ward?.length > 0) {
            let points = [];
            data.Ward.map((e) => {
              let prp = e;

              const x = JSON.parse(e.geom);

              delete prp.geom;

              let d = {
                type: "Feature",
                geometry: x,
                properties: prp,
              };
              points.push(d);
            });
            var geojsonData = {
              type: "FeatureCollection",
              features: points,
            };

            var geojsonSource = new VectorSource({
              features: new GeoJSON().readFeatures(geojsonData),
            });

            ward.setSource(geojsonSource);
            ward.setStyle(
              new Style({
                fill: new Fill({
                  color: "transparent",
                }),
                stroke: new Stroke({
                  color: "blue",
                  width: 2,
                }),
              })
            );
            var extent = geojsonSource.getExtent();
            map?.getView().fit(extent, { padding: [100, 100, 100, 100] });
            setLoading(false);
          }
        })
        .catch((e) => {
          setLoading(false);
        });
    }
  }, [map]);

  useEffect(() => {
    if (data) {
      setVisualization(types[active]);
      setActiveVis(visualization[0]);
    }
  }, [data, active]);

  function getColor() {
    var color = "#";
    var letters = "0123456789ABCDEF";
    // Split the color code into three parts (for each RGB component)
    for (var i = 0; i < 3; i++) {
      var part = "";
      for (var j = 0; j < 2; j++) {
        // Randomly choose a digit from the letters string
        var randomDigit = letters[Math.floor(Math.random() * letters.length)];
        part += randomDigit;
      }
      color += part;
    }
  }

  function removeByTitle(title) {
    map
      .getLayers()
      .getArray()
      .filter((layer) => layer.get("title") === title)
      .forEach((layer) => map.removeLayer(layer));
  }

  function resetMap() {
    if (map) {
      map.getView().fit(ward.getSource().getExtent());
    }
  }

  async function exportMap() {
    if (map == null || draw == null) return;
    const controls = map.getControls().getArray();
    let c = [];
    controls.forEach((control) => {
      if (
        control instanceof Zoom ||
        // control instanceof ScaleLine ||
        control instanceof ZoomToExtent
      ) {
        c.push(control);
        control.setMap(null);
      }
    });
    setControls(c);
    map.addInteraction(draw);
    draw.on("drawend", (event) => {
      const extent = event.feature.getGeometry().getExtent();
      map.getView().fit(extent);
      setDownloading(true);
      drawlayer.setSource(new VectorSource());
      const sc = document.getElementsByClassName("ol-scale-line");
      setScale(sc[0]?.textContent);
      setTimeout(() => {
        setDownloading(false);
        html2canvas(mapElement.current).then(function (canvas) {
          const dataUrl = canvas.toDataURL("image/png");
          map.removeInteraction(draw);
          setDataUrl(dataUrl);
        });
      }, 3000);
    });
  }

  useEffect(() => {
    if (dataUrl == null) {
      controls.forEach((control) => {
        map.addControl(control);
      });
    }
  }, [dataUrl]);

  return (
    <div ref={mapRef} className="gis">
      <div className="container">
        <div className="top">
          <h3>Projects Map</h3>
          <div className="data"></div>
          <div className="filter">
            <button
              onClick={() => {
                setActiveF("Q1");
              }}
              className={activeF == "Q1" ? "active" : ""}
            >
              Q1
            </button>
            <button
              onClick={() => {
                setActiveF("Q2");
              }}
              className={activeF == "Q2" ? "active" : ""}
            >
              Q2
            </button>
            <button
              onClick={() => {
                setActiveF("Q3");
              }}
              className={activeF == "Q3" ? "active" : ""}
            >
              Q3
            </button>
            <button
              onClick={() => {
                setActiveF("Q4");
              }}
              className={activeF == "Q4" ? "active" : ""}
            >
              Q4
            </button>
            <button
              onClick={() => {
                setActiveF(year);
              }}
              className={activeF == year ? "active" : ""}
            >
              {year}
            </button>
            <button
              onClick={() => {
                setActiveF("All Time");
              }}
              className={activeF == "All Time" ? "active" : ""}
            >
              All Time
            </button>
            <Input
              handleChange={(e) => {
                setStart(e);
              }}
              type="date"
              value={start}
              label="Start Date"
            />
            <Input
              handleChange={(e) => {
                setEnd(e);
              }}
              type="date"
              value={end}
              label="End Date"
            />
          </div>
        </div>
        <hr />
        <div className="map">
          <div ref={exportmap}></div>

          <div className="map-element" ref={mapElement}></div>
          {popup && <Popup setPopup={setPopup} data={popup} />}
          <div className="download">
            <div></div>

            <div>
              <a
                onClick={() => {
                  exportMap();
                }}
                role="button"
              >
                <FontAwesomeIcon icon={faDownload} /> Map
              </a>
            </div>
          </div>

          <div className="visualization">
            <Visualization
              data={visualization}
              active={activeVis}
              setActiveVis={setActiveVis}
            />
          </div>

          {loading && <RippleLoading />}
          {dowloading && <Loading />}

          <div
            onClick={() => {
              setBaseSelector(true);
            }}
            className="base_selector"
          >
            <i className="fa fa-map"></i>
          </div>

          <LegendItem unique={unique} activeVis={activeVis} colors={colors} />

          {baseSelector && (
            <Basemaps
              setBaseSelector={setBaseSelector}
              basemap={basemap}
              selected={selected}
              setSelected={setSelected}
            />
          )}
          <RightPanel
            map={map}
            data={data}
            setData={setData}
            setIsLoading={setIsLoading}
            setExtent={setExtent}
            setFilter={setFilter}
            filter={filter}
            resetMap={resetMap}
            showing={props.showing}
            removeByTitle={removeByTitle}
          />

          {dataUrl && (
            <MapTemplate
              dataUrl={dataUrl}
              setDataUrl={setDataUrl}
              unique={unique}
              activeVis={activeVis}
              colors={colors}
              scale={scale}
            />
          )}
        </div>
      </div>
    </div>
  );
}

const LegendItem = (props) => {
  const Legend = (params) => {
    return (
      <div className="legnd">
        {params.img ? (
          <img src={params.img} />
        ) : (
          <div
            style={{
              borderRadius: params.shape == "circle" ? "16px" : "5px",
              backgroundColor: params.color,
              height: params.shape == "line" ? "5px" : "16px",
            }}
          ></div>
        )}
        <p>{params.txt}</p>
      </div>
    );
  };

  function getColor() {
    var color = "#";
    var letters = "0123456789ABCDEF";

    // Split the color code into three parts (for each RGB component)
    for (var i = 0; i < 3; i++) {
      var part = "";
      for (var j = 0; j < 2; j++) {
        // Randomly choose a digit from the letters string
        var randomDigit = letters[Math.floor(Math.random() * letters.length)];
        part += randomDigit;
      }
      color += part;
    }
  }

  return (
    <div className="legend">
      <h4>Legend</h4>
      <hr />
      <div className="list">
        <Legend txt="County Boundary" shape="line" color="red" />
        <Legend txt="Sub County Boundary" shape="line" color="purple" />
        <Legend txt="Ward Boundary" shape="line" color="blue" />
        <Legend txt="Beneficiary" shape="line" img={bn} color="blue" />
        {props.unique.length > 0 &&
          props.unique.map((item, index) => {
            return (
              <Legend
                key={index}
                txt={item}
                shape="circle"
                color={
                  props?.colors?.length <= index
                    ? getColor()
                    : props.colors[index]
                }
              />
            );
          })}
      </div>
    </div>
  );
};

const Visualization = (props) => {
  return (
    <select
      onChange={(e) => {
        props.setActiveVis(e.target.value);
      }}
    >
      {props?.data &&
        props?.data?.length > 0 &&
        props.data.map((item, index) => {
          return (
            <option value={item} key={index}>
              {item}
            </option>
          );
        })}
    </select>
  );
};

const MapTemplate = (props) => {
  const [title, setTitle] = useState("Map Title");
  const [scale, setScale] = useState(1);

  useEffect(() => {
    if (props.scale != "") {
      let d = props.scale.split(" ");

      if (d.length > 0) {
        let x = parseInt(d[0]);
        if (d[1] == "km") {
          x = x * 1000;
        }
        setScale(x);
      }
    }
  }, [props.scale]);

  function getColor() {
    var color = "#";
    var letters = "0123456789ABCDEF";
    for (var i = 0; i < 3; i++) {
      var part = "";
      for (var j = 0; j < 2; j++) {
        var randomDigit = letters[Math.floor(Math.random() * letters.length)];
        part += randomDigit;
      }
      color += part;
    }
  }

  const downloadPdfDocument = () => {
    const input = document.getElementById("document");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");

      let type = canvas.width > canvas.height ? "l" : "p";

      const pdf = new jsPDF(type, "mm", [canvas.width, canvas.height]);
      pdf.addImage(
        imgData,
        "JPEG",
        16,
        16,
        canvas.width - 32,
        canvas.height - 32
      );
      pdf.save(`MEL-MIS Map export.pdf`);
      props.setDataUrl(null);
    });
  };

  const Legend = (params) => {
    return (
      <div className="legnd">
        {params.img ? (
          <img src={params.img} />
        ) : (
          <div
            style={{
              borderRadius: params.shape == "circle" ? "44px" : "5px",
              backgroundColor: params.color,
              height: params.shape == "line" ? "5px" : "16px",
            }}
          ></div>
        )}
        <p>{params.txt}</p>
      </div>
    );
  };

  function withCommas(x) {
    if (x == null) return 0;
    const p = x?.toString();
    if (p?.indexOf("$") != -1 || p?.indexOf("%") != -1) {
      return p?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      if (parseFloat(x) < 1) {
        let o = Math.round(parseFloat(x) * 10) / 10;
        return o?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else {
        let o = Math.round(parseFloat(x));
        return o?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    }
  }

  return (
    <div className="model">
      <div className="maptemplate">
        <div id="document" className="mymap">
          <FontAwesomeIcon
            onClick={() => {
              downloadPdfDocument();
            }}
            icon={faDownload}
            className="fa-download"
          />
          <FontAwesomeIcon
            onClick={() => {
              props.setDataUrl(null);
            }}
            icon={faTimes}
            className="fa-times"
          />
          <div className="mcontainer">
            <input type="text" defaultValue={title} />
            <div className="img">
              <img src={props.dataUrl} alt="" />
            </div>
            <div className="legend1">
              <div>
                <h4>Legend</h4>
                <div className="list">
                  <Legend txt="County Boundary" shape="line" color="red" />
                  <Legend
                    txt="Sub County Boundary"
                    shape="line"
                    color="purple"
                  />
                  <Legend txt="Ward Boundary" shape="line" color="blue" />
                  <Legend
                    txt="Beneficiary"
                    shape="line"
                    img={bn}
                    color="blue"
                  />
                  {props.unique.length > 0 &&
                    props.unique.map((item, index) => {
                      return (
                        <Legend
                          key={index}
                          txt={item}
                          shape="circle"
                          color={
                            props.colors.length <= index
                              ? getColor()
                              : props.colors[index]
                          }
                        />
                      );
                    })}
                </div>
              </div>
              <div className="scale">
                <div>
                  <h5>Scale: 1:{withCommas(scale)}</h5>
                  <img src={northArrow} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
