import "../../Styles/projects.scss";
import { useState } from "react";
import { useEffect } from "react";
import Pagination from "../Util/Pagination";
import WaveLoading from "../Util/WaveLoading";
import Button from "../Util/Button";
import Input from "../Util/Input";
import { isMobile } from "react-device-detect";

export default function Projects(props) {
  const [data, setData] = useState(null);
  const [head, setHead] = useState(null);
  const [offset, setOffset] = useState(0);
  const [count, setCount] = useState(0);
  const [isloading, setIsLoading] = useState(false);
  const [showing, setShowing] = useState(null);
  const [editing, setEditing] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    fetch(`/api/projects/paginated/${offset}`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        setIsLoading(false);
        if (data?.data?.length > 0) {
          const cols = Object.keys(data.data[0]);
          let d = [];
          cols.forEach((item) => {
            if (
              item.toLowerCase() !== "geom" &&
              item.toLowerCase() !== "longitude" &&
              item.toLowerCase() !== "latitude"
            ) {
              d.push(item);
            }
          });
          if (isMobile) {
            setCount(2);
          } else {
            let c = d.length > 5 ? 5 : d.length;
            setCount(c);
          }
          setHead(d);
          setData(data);
        }
      })
      .catch((e) => {
        setIsLoading(false);
      });
  }, [offset]);

  return (
    <div className="projects">
      <h3>Projects</h3>
      <br />
      <div className="prj">
        {data &&
          data?.data?.length > 0 &&
          data?.data?.map((item, i) => {
            return (
              <Item
                setShowing={setShowing}
                key={i}
                index={i}
                data={item}
                head={head}
                count={count}
              />
            );
          })}
      </div>
      {data?.total && (
        <Pagination
          totalItems={data?.total}
          currentPage={offset}
          onPageChange={(v) => {
            setOffset(v);
          }}
        />
      )}

      {isloading && <WaveLoading />}
    </div>
  );
}

const Popup = (props) => {
  const Objective = (params) => {
    const [dt, setDt] = useState(0);
    const [ind, setInd] = useState(null);
    const [v, setV] = useState("0/0");

    useEffect(() => {
      fetch(`/api/projects/objectives/indicators/${params.item.id}`)
        .then((res) => {
          if (res.ok) return res.json();
          else throw Error("");
        })
        .then((data) => {
          if (data.length > 0) {
            setInd(data);
            let w = 0;
            let t = 0;
            data.map((i) => {
              w += i.Value;
              t += i.Target;
            });
            const d = ((w / t) * 100).toFixed(0);
            setV(`${w}/${t}`);
            setDt(d);
          }
        })
        .catch((e) => {});
    }, []);
    return (
      <div className="popobj">
        <h3>{params.item.Objective}</h3>
        <hr />
        <h4>Indicators</h4>
        {ind &&
          ind.length > 0 &&
          ind.map((item, i) => {
            return (
              <div key={i} className="objective">
                <p>
                  {params.i + 1}. {params.item.Objective}{" "}
                </p>{" "}
                <div className="st">
                  <span style={{ width: `${dt}%` }}>{v}</span>
                </div>
                <br />
              </div>
            );
          })}
      </div>
    );
  };

  return (
    <div data-aos="fade-left" className="popup">
      <div className="cont">
        <div className="dets">
          <h3>{props.project.ProjectName}</h3>
          <i
            onClick={() => {
              window.location.href = "/projects/List";
              props.setClicked(false);
            }}
            className="fa fa-times"
          ></i>
        </div>
        <hr />
        <div className="div2equal">
          <div className="u_list">
            <h3>Project Details</h3>
            <hr />
            <p>{props.project.ProjectDescription}</p>
            <br />
            <p>
              <b>Consultant: </b>
              {props.project.Consultant}
            </p>
            <p>
              <b>Budget: </b>
              {props.project.ContractAmount}
            </p>
            <p>
              <b>Start Date: </b>
              {props.project.StartDate}
            </p>
            <p>
              <b>End Date: </b>
              {props.project.EndDate}
            </p>
            <br />
            <hr />
            <p>
              <b>Sub County: </b>
              {props.project.SubCounty}
            </p>
            <p>
              <b>Ward: </b>
              {props.project.Ward}
            </p>
            <div className="div2equal">
              <p>
                <b>Latitude: </b>
                {props.project.Latitude}
              </p>
              <p>
                <b>Longitude: </b>
                {props.project.Longitude}
              </p>
            </div>
            <br />
            <hr />
            <p>
              <b>Department: </b>
              {props.project.Department}
            </p>
            <p>
              <b>Unit: </b>
              {props.project.Unit}
            </p>
            <p>
              <b>Sub Sectors: </b>
              {props.project.SubSectors.map((item, i) => {
                return <p key={i}>{item}, </p>;
              })}
            </p>
          </div>
          <div className="u_list">
            <h3>Milestones</h3>
            <hr />
            {props.objectives &&
              props.objectives.map((item, i) => {
                return <Objective key={i} i={i} item={item} />;
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

const Item = (props) => {
  const [objs, setObjs] = useState(null);
  const [clicked, setClicked] = useState(null);
  const [active, setActive] = useState(false);
  useEffect(() => {
    var todayDate = new Date().toISOString().slice(0, 10);
    const x = new Date(todayDate);
    const y = new Date(props.data.EndDate);
    if (todayDate <= props.data.EndDate) {
      setActive(true);
    }

    fetch(`/api/projects/objectives/${props.data.id}`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        setObjs(data);
      })
      .catch((e) => {});
  }, []);

  const Objective = (params) => {
    const [dt, setDt] = useState(0);
    const [v, setV] = useState("0/0");

    useEffect(() => {
      fetch(`/api/projects/objectives/indicators/${params.item.id}`)
        .then((res) => {
          if (res.ok) return res.json();
          else throw Error("");
        })
        .then((data) => {
          if (data.length > 0) {
            let w = 0;
            let t = 0;
            data.map((i) => {
              w += i.Value;
              t += i.Target;
            });
            const d = ((w / t) * 100).toFixed(0);
            setV(`${w}/${t}`);
            setDt(d);
          }
        })
        .catch((e) => {});
    }, []);

    return (
      <div className="objective">
        <p>
          {params.i + 1}. {params.item.Objective}{" "}
        </p>{" "}
        <div className="st">
          <span style={{ width: `${dt}%` }}>{v}</span>
        </div>
      </div>
    );
  };

  return (
    <div
      className="item"
      onClick={() => {
        setClicked(true);
      }}
    >
      <div className="status">
        <p>{active ? "Active" : "InActive"}</p>
      </div>
      <div className="title">
        <h4>{props.data.EndDate}</h4>
        <p>{props.data.ProjectName}</p>
      </div>
      <div className="desc">
        <p>{props.data.ProjectDescription}</p>
      </div>
      <div className="objs">
        <h4>Milestones</h4>
        {objs &&
          objs.length > 0 &&
          objs.map((item, i) => {
            return <Objective key={i} item={item} i={i} />;
          })}
      </div>
      {clicked && (
        <Popup setClicked={setClicked} project={props.data} objectives={objs} />
      )}
    </div>
  );
};
