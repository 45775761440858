import "../Styles/home.scss";

import Header from "../components/Util/Header";
import Navigation from "../components/Util/Navigation";
import { useState } from "react";
import { useEffect } from "react";
import Settings from "../components/Settings/Settings";
import Departments from "../components/Departments/Departments";
import NewDocument from "../components/Requests/NewDocument";
import Sectors from "../components/Departments/Sectors";
import Projects from "../components/Data/Projects";
import UserHome from "../components/Users/UserHome";
import ReportsHome from "../components/Reports/ReportsHome";
import WPStats from "../components/Stats/WPStats";
import WorkPlan from "../components/WorkPlan/WorkPlan";
import MyMap from "../components/Maps/MyMap";

export default function Home(props) {
  const [showing, setShowing] = useState(true);
  const pathname = window.location.pathname.split("/");

  const handleResize = () => {
    if (window.innerWidth < 768) {
      setShowing(false);
    } else {
      setShowing(true);
    }
  };

  useEffect(() => {
    handleResize();
  }, []);

  return (
    <div className="home">
      <div
        style={{ gridTemplateColumns: !showing ? "auto 1fr" : "250px 1fr" }}
        className="main"
      >
        <div className="left_panel">
          <Navigation showing={showing} setShowing={setShowing} />
        </div>
        <div className="right_panel">
          <Header showing={showing} setShowing={setShowing} />
          <div className="full">
            {pathname[1] === "" && <WPStats showing={showing} />}
            {pathname[1] === "gis" && <MyMap showing={showing} />}
            {pathname[1] === "reports" && <ReportsHome showing={showing} />}
            {pathname[1] === "users" && <UserHome />}
            {pathname[1] === "projects" && pathname[2] === "List" && (
              <Projects
                url="/api/data/projects"
                title="Projects Data"
                showing={showing}
              />
            )}
            {pathname[1] === "projects" && pathname[2] === "New" && (
              <NewDocument showing={showing} />
            )}
            {pathname[1] === "me" && <WorkPlan showing={showing} />}
            {pathname[1] === "departments" && pathname[2] === "Departments" && (
              <Departments showing={showing} />
            )}
            {pathname[1] === "departments" && pathname[2] === "Sectors" && (
              <Sectors showing={showing} />
            )}
            {pathname[1] === "settings" && <Settings showing={showing} />}
          </div>
        </div>
      </div>
    </div>
  );
}
