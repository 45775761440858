import { useEffect, useRef, useState } from "react";
import "../../Styles/workplan.scss";
import DailyWP from "./DailyWP";
import MonthWP from "./MonthWP";
import SoWP from "./SoWP";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRotate } from "@fortawesome/free-solid-svg-icons";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import CreateWorkPlan from "./CreateWorkPlan";

export default function WorkPlan(props) {
  const [active, setActive] = useState("Daily View");
  const [refresh, setRefresh] = useState(false);
  const [download, setDownload] = useState(false);
  const [createWP, setCreateWP] = useState(false);

  return (
    <div className="workplan">
      <div className="container">
        <div className="topbar">
          <div
            onClick={() => {
              setCreateWP(true);
            }}
            className="new"
          >
            <FontAwesomeIcon className="fa-times" icon={faPlus} />
            <p>Create Work Plan</p>
          </div>
          <div className="top">
            <FontAwesomeIcon
              onClick={() => {
                setRefresh(!refresh);
              }}
              className="fa-icon"
              icon={faArrowsRotate}
            />
            <FontAwesomeIcon
              onClick={() => {
                setDownload(true);
              }}
              className="fa-icon"
              icon={faDownload}
            />

            <TopItem active={active} setActive={setActive} txt="Daily View" />
            <TopItem active={active} setActive={setActive} txt="Month View" />
            <div></div>
            {/* <TopItem active={active} setActive={setActive} txt="Officer View" /> */}
          </div>
        </div>

        {active === "Daily View" && (
          <DailyWP
            refresh={refresh}
            setRefresh={setRefresh}
            download={download}
            setDownload={setDownload}
          />
        )}
        {active === "Month View" && (
          <MonthWP
            refresh={refresh}
            setRefresh={setRefresh}
            download={download}
            setDownload={setDownload}
          />
        )}

        {active === "Officer View" && (
          <SoWP
            refresh={refresh}
            setRefresh={setRefresh}
            download={download}
            setDownload={setDownload}
          />
        )}
      </div>
      {createWP && <CreateWorkPlan setCreateWP={setCreateWP} />}
    </div>
  );
}

const TopItem = (props) => {
  return (
    <div
      className={props.txt === props.active ? "active" : "topitem"}
      onClick={() => {
        props.setActive(props.txt);
      }}
    >
      <p>{props.txt}</p>
    </div>
  );
};
