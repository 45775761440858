import { useEffect, useRef, useState } from "react";
import "../../Styles/departments.scss";
import Input from "../Util/Input";
import WaveLoading from "../Util/WaveLoading";

export default function Sectors(props) {
  const [dpt, setDpt] = useState(null);
  const [data, setData] = useState(null);

  useEffect(() => {
    fetch("/api/sectors")
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        setData(data);
      })
      .catch((e) => {});
  }, []);

  return (
    <div className="departments">
      <div className="list">
        <h3>Sectors</h3>
        <br />
        <div className="content">
          {data &&
            data.length > 0 &&
            data.map((item, i) => {
              return <Dept key={i} item={item} />;
            })}
        </div>
        <div className="add">
          <button
            onClick={() => {
              setDpt(true);
            }}
          >
            Add New
          </button>
        </div>
      </div>
      {dpt && <NewDepartment setDpt={setDpt} />}
    </div>
  );
}

const Dept = (props) => {
  const [opencls, setOpenCls] = useState(null);
  const [data, setData] = useState(null);

  useEffect(() => {
    fetch(`/api/classifications/bydepartment/${props.item.id}`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        setData(data);
      })
      .catch((e) => {});
  }, [props.item]);

  return (
    <div className="dept">
      <h3>{props.item.Sector}</h3>
      <div className="units">
        <h4>Sub Sectors: </h4>
        <hr />
        <div className="unit">
          {props.item.SubSectors.map((item, i) => {
            return (
              <p>
                {item}
                {props.item.SubSectors.length == i + 1 ? "" : ","}
              </p>
            );
          })}
        </div>
      </div>

      {opencls && (
        <NewClassification item={props.item} setOpenCls={setOpenCls} />
      )}
    </div>
  );
};

const NewDepartment = (props) => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(null);
  const [data, setData] = useState(null);
  const [adding, setAdding] = useState(false);
  const [checks, setChecks] = useState([]);
  const [body, setBody] = useState({
    Sector: null,
    SubSectors: null,
  });
  const nm = useRef();
  const addnew = useRef();
  const sf = useRef();
  const idno = useRef();
  const nid = useRef();

  const createDocument = (e) => {
    setError("");
    let d = body;
    d.Sector = nm.current.value;
    d.SubSectors = checks;
    const chck = Object.values(d);
    let valid = true;
    chck.map((item) => {
      if (item == "" || item == null || item == []) {
        valid = false;
      }
    });
    if (!valid) return setError("All fields are required!");

    setLoading(true);

    fetch("/api/sectors/create", {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(d),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else throw Error("");
      })
      .then((data) => {
        setLoading(false);
        if (data.success) {
          setError(data.success);
          setTimeout(() => {
            props.setDpt(null);
            window.location.href = "/departments/Sectors";
          }, 500);
        } else {
          setError(data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        setError("Oops! Something went wrong!");
      });
  };

  const List = (params) => {
    return (
      <div className="s_list">
        <p>{params.item}</p>
        <i
          onClick={() => {
            setChecks(checks.filter((a) => a !== params.item));
          }}
          className="fa fa-times"
        ></i>
      </div>
    );
  };

  return (
    <div className="popup">
      <div className="pcontainer">
        <h3>New Sector</h3>
        <i
          onClick={() => {
            props.setDpt(null);
          }}
          className="fa fa-times"
        ></i>
        <hr />
        <div className="content">
          <Input ref={nm} type="text" label="Sector *" />
          <br />

          <div className="checks">
            <h4>Add Sub Sectors *</h4>
            <div>
              {checks &&
                checks.map((item, i) => {
                  return <List key={i} index={i} item={item} />;
                })}
            </div>
            <div className="addnew">
              <input type="text" ref={addnew} />
              <i
                onClick={() => {
                  setChecks([...checks, addnew.current.value]);
                }}
                className="add"
              >
                Add New
              </i>
            </div>
          </div>
          <br />
          <h6>{error}</h6>
          <br />
          <button
            onClick={() => {
              createDocument();
            }}
          >
            Submit
          </button>
        </div>
        {loading && <WaveLoading />}
      </div>
    </div>
  );
};

const NewClassification = (props) => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(null);
  const [data, setData] = useState(null);
  const [adding, setAdding] = useState(false);
  const [checks, setChecks] = useState([]);
  const [body, setBody] = useState({
    Classification: null,
    Classes: null,
    DepartmentID: props.item.id,
  });
  const nm = useRef();
  const addnew = useRef();
  const sf = useRef();
  const idno = useRef();
  const nid = useRef();

  const createDocument = (e) => {
    setError("");
    let d = body;
    d.Classification = nm.current.value;
    d.Classes = checks;
    const chck = Object.values(d);
    let valid = true;
    chck.map((item) => {
      if (item == "" || item == null || item == []) {
        valid = false;
      }
    });
    if (!valid) return setError("All fields are required!");

    setLoading(true);

    fetch("/api/classifications/create", {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(d),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else throw Error("");
      })
      .then((data) => {
        setLoading(false);
        if (data.success) {
          setError(data.success);
          setTimeout(() => {
            props.setOpenCls(null);
            window.location.href = "/departments/Departments";
          }, 500);
        } else {
          setError(data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        setError("Oops! Something went wrong!");
      });
  };

  const List = (params) => {
    return (
      <div className="s_list">
        <p>{params.item}</p>
        <i
          onClick={() => {
            setChecks(checks.filter((a) => a !== params.item));
          }}
          className="fa fa-times"
        ></i>
      </div>
    );
  };

  return (
    <div className="popup">
      <div className="pcontainer">
        <h3>New Classification</h3>
        <i
          onClick={() => {
            props.setDpt(null);
          }}
          className="fa fa-times"
        ></i>
        <hr />
        <div className="content">
          <Input ref={nm} type="text" label="Classification *" />
          <br />

          <div className="checks">
            <h4>Add Classes *</h4>
            <div>
              {checks &&
                checks.map((item, i) => {
                  return <List key={i} index={i} item={item} />;
                })}
            </div>
            <div className="addnew">
              <input type="text" ref={addnew} />
              <i
                onClick={() => {
                  setChecks([...checks, addnew.current.value]);
                }}
                className="add"
              >
                Add New
              </i>
            </div>
          </div>
          <br />
          <h6>{error}</h6>
          <br />
          <button
            onClick={() => {
              createDocument();
            }}
          >
            Submit
          </button>
        </div>
        {loading && <WaveLoading />}
      </div>
    </div>
  );
};
