import "../Styles/login.scss";
import { useEffect, useRef, useState } from "react";
import WaveLoading from "../components/Util/WaveLoading";
import Input from "../components/Util/Input";

export default function Login(props) {
  const rfEmail = useRef();
  const rsEmail = useRef();
  const rfPassword = useRef();
  const [isErr, setIsErr] = useState("");
  const [loading, setLoading] = useState(false);
  const [body, updateBody] = useState({
    Email: null,
  });
  const ref = useRef();
  const [active, setActive] = useState("login");
  const [leftA, setLeftA] = useState("100%");
  const [rightA, setRightA] = useState("-100%");
  const [leftB, setLeftB] = useState("-100%");
  const [rightB, setRightB] = useState("100%");

  useEffect(() => {
    if (active == "login") {
      setLeftA(0);
      setRightA("50%");
      setLeftB("-100%");
      setRightB("100%");
    } else {
      setLeftB("50%");
      setRightB(0);
      setLeftA("100%");
      setRightA("-100%");
    }
  }, [active]);

  const login = () => {
    let d = body;
    d.Email = rfEmail.current.value.toLowerCase().trim();
    d.Password = rfPassword.current.value;
    updateBody(d);
    setIsErr("");

    if (!validateEmail(body.Email))
      return setIsErr("Please Enter a Valid Email Address!");
    if (!validatePassword(body.Password))
      return setIsErr("Password must be at least 6 characters!");

    if (validateEmail(body.Email) && validatePassword(body.Password)) {
      setLoading(true);
      fetch("/api/auth/login", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw Error("Login Failed");
          }
        })
        .then((data) => {
          if (data.success) {
            localStorage.setItem("gdfhgfhtkn", data.token);
            localStorage.removeItem("path");
            setIsErr(data.success);
            setLoading(false);
            window.location.href = "/";
          } else {
            setLoading(false);
            setIsErr(data.error);
          }
        })
        .catch((err) => {
          setLoading(false);
          setIsErr("Login failed");
        });
    }
  };

  const reset = () => {
    let d = body;
    d.Email = rsEmail.current.value.toLowerCase().trim();
    updateBody(d);
    setIsErr("");

    if (!validateEmail(body.Email))
      return setIsErr("Please Enter a Valid Email Address!");

    if (validateEmail(body.Email)) {
      setLoading(true);
      fetch("/api/auth/forgot", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw Error("Login Failed");
          }
        })
        .then((data) => {
          if (data.success) {
            localStorage.removeItem("path");
            setIsErr(data.success);
            setLoading(false);
            window.location.href = "/";
          } else {
            setLoading(false);
            setIsErr(data.error);
          }
        })
        .catch((err) => {
          setLoading(false);
          setIsErr("Login failed");
        });
    }
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]/.,;:\s@"]+(\.[^<>()[\]/.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const validatePassword = (password) => {
    return password.length >= 6;
  };

  return (
    <div className="login" ref={ref}>
      <div className="container">
        <div
          style={{
            opacity: active == "login" ? 1 : 0,
            display: active == "login" ? "block" : "none",
          }}
        >
          <div id="sign-in" className={"sign-in "} style={{ left: leftA }}>
            <form
              action=""
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <h1>Sign In</h1>
              <h6>{isErr}</h6>
              <Input ref={rfEmail} type="email" label="Email" />
              <Input ref={rfPassword} type="password" label="Password" />
              <a href="">Forget your password?</a>
              <button
                onClick={() => {
                  login();
                }}
              >
                Sign In
              </button>
            </form>
          </div>
          <div className="right-panel" style={{ left: rightA }}>
            <div>
              <h1>Welcome! PMIS</h1>
              <p>
                Project Management Information System for the County Government
                of Kitui.
              </p>
              <button
                onClick={() => {
                  setActive("reset");
                }}
                className="hidden"
              >
                Reset Password
              </button>
            </div>
          </div>
        </div>
        <div
          style={{
            opacity: active == "reset" ? 1 : 0,
            display: active == "reset" ? "block" : "none",
          }}
        >
          <div className={"reset"} style={{ left: leftB }}>
            <form
              action=""
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <h1>Reset Password</h1> <h6>{isErr}</h6>
              <Input ref={rsEmail} type="email" label="Email" />
              <button
                onClick={() => {
                  reset();
                }}
              >
                Submit
              </button>
            </form>
          </div>
          <div className="left-panel" style={{ left: rightB }}>
            <div>
              <h1>Welcome! PMIS</h1>
              <p>
                Project Management Information System for the County Government
                of Kitui.
              </p>
              <button
                onClick={() => {
                  setActive("login");
                }}
                className="hidden"
              >
                Sign In
              </button>
            </div>
          </div>
        </div>
      </div>
      {loading && <WaveLoading />}
    </div>
  );
}
