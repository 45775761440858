import { useEffect, useState } from "react";
import ReportSelect from "../Reports/ReportsManagement/ReportSelect";
import CircleLoading from "../Util/CircleLoading";
import WaveLoading from "../Util/WaveLoading";
import Select from "../Util/Select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faAngleRight,
  faTimeline,
  faTimes,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import Pagination from "../Util/Pagination";

export default function MonthWP(props) {
  const jwt = require("jsonwebtoken");
  const { getMonths } = require("../../assets/data/data.js");
  const [data, setData] = useState(null);
  const [year, setYear] = useState("2023");
  const [today, setToday] = useState("");
  const [month, setMonth] = useState("January");
  const [mm, setMM] = useState([]);
  const [dd, setDD] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const date = new Date().toISOString().split("T")[0].split("-");
    setYear(date[0]);
    const d = new Date().toISOString().split("T")[0];
    setToday(d);
    const mi = getMonths
      .map((item) => {
        return item.code;
      })
      .indexOf(date[1]);
    if (mi != -1) {
      setMonth(getMonths[mi].month);
    }
  }, []);

  useEffect(() => {
    let d = [];
    getMonths.map((item) => {
      d.push(item["month"]);
    });
    setMM(d);
    const mi = getMonths
      .map((item) => {
        return item.month;
      })
      .indexOf(month);

    if (mi != -1) {
      let x = [];
      for (let index = 1; index < getMonths[mi].days + 1; index++) {
        if (index == 1) {
          const d = new Date(`${year}-${getMonths[mi].code}-01`);

          for (let index = 0; index < d.getDay(); index++) {
            x.push(null);
          }
          const dd = index < 10 ? "0" + index : index.toString();
          const date =
            year.toString() + "-" + getMonths[mi].code.toString() + "-" + dd;
          x.push({
            date: date,
          });
        } else {
          const dd = index < 10 ? "0" + index : index.toString();
          const date =
            year.toString() + "-" + getMonths[mi].code.toString() + "-" + dd;
          x.push({
            date: date,
          });
        }
      }
      if (x.length < 35) {
        const size = 35 - x.length;
        for (let index = 0; index < size; index++) {
          x.push(null);
        }
      }
      setDD(x);
    }
  }, [month, year, props.refresh]);

  useEffect(() => {
    if (props.download && data) {
      downloadCSV(data, "monthly work plan summary.csv");
      props.setDownload(false);
    }
  }, [props.download]);

  function downloadCSV(jsonArray, filename) {
    const headerRow = Object.keys(jsonArray[0]).join(",") + "\n";
    const csvContent = jsonArray
      .map((row) => {
        return Object.values(row).join(",");
      })
      .join("\n");
    const fullCSV = headerRow + csvContent;
    const blob = new Blob([fullCSV], { type: "text/csv" });
    const downloadLink = document.createElement("a");
    downloadLink.href = window.URL.createObjectURL(blob);
    downloadLink.download = filename;
    downloadLink.click();
  }

  useEffect(() => {
    setLoading(true);
    fetch(`/api/workplan/searchmonth/month`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        setData(data);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  }, []);

  return (
    <div className="monthwp">
      <div className="month">
        <h4>
          {month}, {year}
        </h4>
        <Select
          setChanged={(v) => {
            setMonth(v);
          }}
          data={mm}
          value={month}
        />
        <Select
          setChanged={(v) => {
            setYear(v);
          }}
          value={year}
          data={["2023", "2024", "2025", "2026"]}
        />
      </div>
      <div className="mlist">
        <div className="dow">
          <h4>Sun</h4>
          <h4>Mon</h4>
          <h4>Tue</h4>
          <h4>Wed</h4>
          <h4>Thu</h4>
          <h4>Fri</h4>
          <h4>Sat</h4>
        </div>
        <div className="calendar">
          {dd.map((item, i) => {
            return <Day key={i} item={item} today={today} data={data} />;
          })}
        </div>
      </div>
      {loading && <WaveLoading />}
    </div>
  );
}

const Day = (props) => {
  const [data, setData] = useState("0");
  const [showDetails, setShowingDetails] = useState(false);

  useEffect(() => {
    if (props.item && props.data && props.data.length > 0) {
      const i = props.data
        .map((i) => {
          return i?.Date;
        })
        .indexOf(props?.item?.date);
      if (i != -1) setData(props.data[i]?.count);
    }
  }, [props.item, props.data]);

  return (
    <>
      {props.item == null ? (
        <div
          style={{
            backgroundColor: "#60606010",
          }}
          className="day mob"
        ></div>
      ) : (
        <div
          onClick={() => {
            setShowingDetails(true);
          }}
          className="day"
        >
          <h6>{props.item.date.split("-")[2]}</h6>
          <h4>{data}</h4>
        </div>
      )}
      {data != "0" && showDetails && props.item && (
        <DetailsPopup setShowingDetails={setShowingDetails} item={props.item} />
      )}
    </>
  );
};

const DetailsPopup = (props) => {
  const [data, setData] = useState(null);
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    if (props.item != null && data == null) {
      setData(null);
      fetch(`/api/workplan/searchdate/${props.item.date}/${offset}`)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else throw Error();
        })
        .then((data) => {
          console.log(data);
          setData(data);
        })
        .catch((e) => {});
    }
  }, [offset]);

  return (
    <div className="container mdetails">
      <div className="cpcontainer">
        <div className="tp">
          <h3>Workplans List</h3>
          <FontAwesomeIcon
            className="close"
            onClick={() => {
              props.setShowingDetails(false);
            }}
            icon={faTimes}
          />
        </div>
        <div className="bdy section">
          <div className="div3equal">
            {data &&
              data?.data?.length > 0 &&
              data?.data?.map((item, i) => {
                return <SOItem key={i} item={item} />;
              })}
          </div>
          {data && (
            <Pagination
              totalItems={data?.total}
              currentPage={offset}
              onPageChange={(e) => {
                setOffset(e);
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const SOItem = (props) => {
  const [init, setInit] = useState("");
  useEffect(() => {
    const nm = props.item.Name.split(" ");
    if (nm.length > 1) {
      setInit(nm[0].substring(0, 1) + nm[1].substring(0, 1));
    } else setInit(nm[0].substring(0, 1));
  }, []);

  return (
    <>
      <div className="soitem">
        <div className="title">
          <div className="left">
            <h2>{init}</h2>
          </div>
          <div>
            <h4>{props.item.Name}</h4>
            <p>
              <b>Phone: </b> {props.item.Phone}
            </p>
            <p>
              <b>Employee No: </b> {props.item.EmployeeNo}
            </p>
          </div>
        </div>

        <h4>Workplan Details</h4>
        <hr />
        <div className="div2equal">
          <p className="task">
            <b>Task: </b> {props.item.Objective}
          </p>
          <p>
            <b>Date: </b> {props.item.Date}
          </p>
        </div>

        <h4>Indicator Details</h4>
        <hr />
        <p>
          <b>Current Value: </b>
          {props.item.Value}
        </p>
        <p>
          <b>Target: </b> {props.item.Target}
        </p>
        <br />
        <h4>Supervision</h4>
        <hr />
        <p>
          <b>Supervisor: </b>
          {props.item.supervisor}
        </p>
        <p>
          <b>Department: </b> {props.item.Department}
        </p>
      </div>
    </>
  );
};
